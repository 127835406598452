// src/App.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Table, Alert } from 'react-bootstrap';

const App = () => {

  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const [resultsProvincia, setResultsProvincia] = useState([]);

  const options = ['81CD781AF38F61C49A42', 'FE065AB52323227ADEB8'];

  const [SN, setSN] = useState('');
  const [hwid, setHwid] = useState('7427EA9AD57B');
  const [bt, setBt] = useState('19');


  useEffect(() => {
    
    const fetchData = async () => {
      try {
        // Crear las promesas para ambas solicitudes
        const responses = await Promise.all(options.map(option => {
          let PARAMETROS = `?SN=${option}&HWID=${hwid}&BT=${bt}`;
          return fetch('https://script.google.com/macros/s/AKfycbxBbYHBuHjqMPhGvwpY-RiqU3pTXOwbl-ibg6Lp3sRHiSJGS0uShno4vyF8iAkvy_nMAw/exec' + PARAMETROS)
            .then(response => {
              if (!response.ok) throw new Error('Error al obtener los datos');
              return response.json();
            });
        }));

        // Guardar los resultados en el estado
        setResults(responses);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchProvincia = async () => {
      try {
        const msn = '3C3631CBCF61392DA3848E1EF24A0513';
        const responses = await Promise.all([
          fetch('https://api-desbloqueos.educacioncba.edu.ar/api/v1/pixart/devices?msn=' + msn)
            .then(response => {
              if (!response.ok) throw new Error('Error al obtener los datos');
              return response.json();
            })
        ]);
    
        // Guardar los resultados en el estado
        //console.log(responses);
        setResultsProvincia(responses);
      } catch (err) {
        console.error(err.message);
        // setError(err.message); // Descomentar si tienes un estado de error
      } finally {
        // setLoading(false); // Descomentar si tienes un estado de carga
      }
    };



    fetchData();
    fetchProvincia();

  }, [hwid, bt]);

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <Container>
      {results.map((result, index) => (
        <div key={index}>
          <hr />
          <h4 className='mt-4'>Servidor [ {options[index]} ]</h4>
          <h5>Estado: <p className={result.request ? 'text-success' : 'text-danger'}>{result.request ? 'OK' : 'NO RESPONDE'}</p></h5>
          <hr />
          hwid: {hwid}
          <br />
          bt: {bt}
          <p>Solución: {result.solucion}</p>
          <hr />
        </div>
      ))}


          <div>
          <hr />
          <h4 className='mt-4'>Servidor [ Pixart ]</h4>
          <h5>Estado: <p className={resultsProvincia[0].resultado ? 'text-success' : 'text-danger'}>{resultsProvincia[0].resultado  ? 'OK' : 'NO RESPONDE'}</p></h5>
          <hr />

          <p>Información: {resultsProvincia[0].info}</p>
          <p>
             msn: {resultsProvincia[0].data[0].msn}
             <br/>  
             id: {resultsProvincia[0].data[0].id}
             <br/>
             Bios Number: {resultsProvincia[0].data[0].bios_number}
             <br/>             
          </p>
         
          <hr />
        </div>
   



</Container>
  );
};

export default App;